import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import { graphql, Link, useStaticQuery, PageProps } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Seo from '../components/Seo/seo';
import { Layout } from '../components/Layout';
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image';
import Travel from '../components/Travel/TravelView';
import { getImageByRelativePath, isBrowser } from '../shared/utilities';
import { Timeline } from '../components';
import { getTrips } from '../components/Timeline/Trips';
import useWindowDimensions from '../hooks/useWindowDimensions';

type IslandHoppingQueryResult = {
    [key: string]: {
        childImageSharp: IGatsbyImageData,
    }
}

const IslandHoppingPage: FC<PageProps<IslandHoppingQueryResult>> = ({ data }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'islandHoppingPage' });
    const [state, setState] = useState({
        index: 0
    });

    const timelineRef: RefObject<HTMLHRElement> = useRef(null);
    useEffect(() => {
        const executeScroll = (myRef: RefObject<HTMLDivElement>) => myRef.current && myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (isBrowser() && window.history.state && window.history.state.fromHome) {
            executeScroll(timelineRef);
            setState({ ...state, index: window.history.state.index });
        }
    }, []);

    function goToTrip(tripIndex: number) {
        setState({ ...state, index: tripIndex });
        timelineRef.current && timelineRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const { width } = useWindowDimensions();
    function getBanner() {
        var banner
        if (width > 768) {
            banner = <StaticImage
                className={'banner'}
                src="../images/banner2.jpg"
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />

        }
        else {
            banner = <StaticImage
                className={'banner'}
                src='../images/banner2_mobile.jpg'
                quality={95}
                formats={['auto', 'webp', 'avif']}
                loading='eager'
                alt='Intro banner'
            />
        }
        return banner
    }
    const banner = getBanner();

    return (
        <Layout>
            <Seo title={"Eilandhoppen"} description={"Laat je inspireren door onze zes favoriete reizen."} />

            {banner}

            <h3 style={{}}>“We don't remember days, we remember moments”</h3>
            <div style={{
                textAlign: 'center',
                position: 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '1.5rem 0 1.5rem 0',
                boxShadow: '0 0 10px rgb(72 72 72 / 16%)',
                zIndex: '200',
                backgroundColor: '#ffffff',
                marginBottom: '50px'

            }}>
                <StaticImage
                    className='palmleaf'
                    style={{
                        position: 'absolute',
                        zIndex: '1', width: '23rem',
                        right: '0%',
                        bottom: '0',
                        opacity: '0.1',
                        transform: 'scaleX(-1)'
                    }}
                    src='../images/Palmleaf.png'
                    quality={95}
                    formats={['auto', 'webp', 'avif']}
                    loading='eager'
                    alt='Intro banner'
                />

                <div className='main_text column' style={{ alignItems: 'center' }} >
                    <div style={{ paddingBottom: '30px' }}>{t('introtext')} </div>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='read_more_btn' style={{ height: '50px', width: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/contact/'>{t('introbutton')}</Link>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{ alignItems: 'center' }} className='column'>
                <div className='wrapped-row'>
                    <Travel features={[t('travelblock1header'), t('travelblock1feature1'),t('travelblock1feature2'),t('travelblock1feature3'),t('travelblock1feature4')]} left_to_right={false} image={getImageByRelativePath('FOTO12.jpg', data)} duration="5" price="1499" linkButton={<button onClick={() => goToTrip(0)} type='button' className='read_more_btn'>{t('readmoreBtn')}</button>} />
                    <Travel features={[t('travelblock2header'), t('travelblock2feature1'),t('travelblock2feature2'),t('travelblock2feature3'),t('travelblock2feature4')]} left_to_right={false} image={getImageByRelativePath('FOTO13.jpg', data)} duration="7" price="1799" linkButton={<button onClick={() => goToTrip(1)} type='button' className='read_more_btn'>{t('readmoreBtn')}</button>} />
                    <Travel features={[t('travelblock3header'), t('travelblock3feature1'),t('travelblock3feature2'),t('travelblock3feature3'),t('travelblock3feature4')]} left_to_right={false} image={getImageByRelativePath('FOTO2.jpg', data)} duration="8" price="2299" linkButton={<button onClick={() => goToTrip(2)} type='button' className='read_more_btn'>{t('readmoreBtn')}</button>} />
                </div>
                <div className='wrapped-row'>
                    <Travel features={[t('travelblock4header'), t('travelblock4feature1'),t('travelblock4feature2'),t('travelblock4feature3'),t('travelblock4feature4')]} left_to_right={false} image={getImageByRelativePath('reis4_banner.jpg', data)} duration="10" price="2799" linkButton={<button onClick={() => goToTrip(3)} type='button' className='read_more_btn'>{t('readmoreBtn')}</button>} />
                    <Travel features={[t('travelblock5header'), t('travelblock5feature1'),t('travelblock5feature2'),t('travelblock5feature3'),t('travelblock5feature4')]} left_to_right={false} image={getImageByRelativePath('FOTO3.jpg', data)} duration="12" price="2999" linkButton={<button onClick={() => goToTrip(4)} type='button' className='read_more_btn'>{t('readmoreBtn')}</button>} />
                    <Travel features={[t('travelblock6header'), t('travelblock6feature1'),t('travelblock6feature2'),t('travelblock6feature3'),t('travelblock6feature4')]} left_to_right={false} image={getImageByRelativePath('FOTO4.jpg', data)} duration="10" price="2550" linkButton={<button onClick={() => goToTrip(5)} type='button' className='read_more_btn'>{t('readmoreBtn')}</button>} />
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', margin: '50px 0' }}>
                <div className='read_more_btn' style={{ height: '65px', width: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/contact/'>Stel jouw droomreis samen</Link>
                </div>
            </div>

            <hr ref={timelineRef} style={{ margin: '40px 400px' }}></hr>

            {getTrips(data).map((trip, index) => <div className={index !== state.index ? 'hidden' : ''} key={trip.metaData.title}><Timeline trip={trip} /></div>)}

            <div style={{ height: 180 }} /> {/* Spacing for timeline */}
            <div style={{ display: 'flex', justifyContent: 'center', margin: '50px 0' }}>
                <div className='read_more_btn' style={{ height: '65px', width: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link style={{ color: '#fff', textDecoration: 'none', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} to='/contact/'>Boek nu</Link>
                </div>
            </div>


            <hr style={{ margin: '40px 400px' }}></hr>
            <div className='logo_row'
                style={{
                    width: '100%',
                    display: `flex`,
                    alignItems: `center`,
                    justifyContent: `space-around`,
                    marginTop: '20px'
                }}
            >
                    <GatsbyImage style={{ margin: '-20px 20px 0 20px' }} className={`meta-image`} alt='' image={getImageByRelativePath("logo_footer1_bewerkt.png", data)}></GatsbyImage>
                    <GatsbyImage style={{ margin: '-20px 20px 0 20px' }} className={`meta-image`} alt='' image={getImageByRelativePath("logo_footer2_bewerkt.png", data)}></GatsbyImage>
                    <GatsbyImage style={{ margin: '-20px 20px 0 20px' }} className={`meta-image`} alt='' image={getImageByRelativePath("logo_footer3_bewerkt.png", data)}></GatsbyImage>
                    <GatsbyImage style={{ margin: '-20px 20px 0 20px' }} className={`meta-image`} alt='' image={getImageByRelativePath("logo_footer4_bewerkt.png", data)}></GatsbyImage>


            </div>

        </Layout>
    )
}

export default IslandHoppingPage;

export const imageFragment = graphql`
  fragment imageFragment on File {
    childImageSharp {
      gatsbyImageData(width: 900)
    }
    relativePath
  }
`

export const query = graphql`
  {
    image1: file(relativePath: { eq: "bartolome.jpg"}) {
      ...imageFragment
    }
    image2: file(relativePath: { eq: "couple_snorkling.jpeg"}) {
      ...imageFragment
    }
    image3: file(relativePath: { eq: "eagle_ray.jpg"}) {
      ...imageFragment
    }
    image4: file(relativePath: { eq: "espanola.jpg"}) {
      ...imageFragment
    }
    image5: file(relativePath: { eq: "ferry_galapagos.jpg"}) {
      ...imageFragment
    }
    image6: file(relativePath: { eq: "flamingo_isabela.jpg"}) {
      ...imageFragment
    }
    image7: file(relativePath: { eq: "flamingo.jpg"}) {
      ...imageFragment
    }
    image8: file(relativePath: { eq: "foto_darwin_center.jpg"}) {
      ...imageFragment
    }
    image9: file(relativePath: { eq: "hammerhead.jpg"}) {
      ...imageFragment
    }
    image10: file(relativePath: { eq: "kajak.jpg"}) {
      ...imageFragment
    }
    image11: file(relativePath: { eq: "kicker_rock.jpg"}) {
      ...imageFragment
    }
    image12: file(relativePath: { eq: "kokosnoot.jpeg"}) {
      ...imageFragment
    }
    image13: file(relativePath: { eq: "north_seymour.jpg"}) {
      ...imageFragment
    }
    image14: file(relativePath: { eq: "rays_from_boat.jpg"}) {
      ...imageFragment
    }
    image15: file(relativePath: { eq: "santiago.jpg"}) {
      ...imageFragment
    }
    image16: file(relativePath: { eq: "sea_lions.jpg"}) {
      ...imageFragment
    }
    image17: file(relativePath: { eq: "tortoise.jpg"}) {
      ...imageFragment
    }
    image18: file(relativePath: { eq: "tortois_hooglanden.jpg"}) {
      ...imageFragment
    }
    image19: file(relativePath: { eq: "snorkeling.jpeg"}) {
      ...imageFragment
    }
    image20: file(relativePath: { eq: "leguaan.jpg"}) {
      ...imageFragment
    }
    image21: file(relativePath: { eq: "coffee.jpeg"}) {
      ...imageFragment
    }
    image22: file(relativePath: { eq: "mantaray.jpeg"}) {
      ...imageFragment
    }
    image23: file(relativePath: { eq: "stock6.jpeg"}) {
      ...imageFragment
    }
    image24: file(relativePath: { eq: "stock9.jpg"}) {
      ...imageFragment
    }
    image25: file(relativePath: { eq: "wonderwater_galapagos.JPG"}) {
      ...imageFragment
    }
    image26: file(relativePath: { eq: "tijd_voor_actie.JPG"}) {
      ...imageFragment
    }
    image27: file(relativePath: { eq: "reis1_dag2.JPG"}) {
      ...imageFragment
    }
    image28: file(relativePath: { eq: "reis2_dag3.JPG"}) {
      ...imageFragment
    }
    image29: file(relativePath: { eq: "reis2_dag4.jpg"}) {
      ...imageFragment
    }
    image30: file(relativePath: { eq: "reis2_dag6.jpg"}) {
      ...imageFragment
    }
    image31: file(relativePath: { eq: "reis3_banner.jpg"}) {
      ...imageFragment
    }
    image32: file(relativePath: { eq: "reis4_banner.jpg"}) {
      ...imageFragment
    }
    image33: file(relativePath: { eq: "reis5_banner.jpg"}) {
      ...imageFragment
    }
    image34: file(relativePath: { eq: "reis6_banner.jpg"}) {
      ...imageFragment
    }
    image35: file(relativePath: { eq: "reis3_dag2.JPG"}) {
      ...imageFragment
    }
    image36: file(relativePath: { eq: "stock2.jpg"}) {
      ...imageFragment
    }
    image37: file(relativePath: { eq: "reis3_dag3.jpg"}) {
      ...imageFragment
    }
    image38: file(relativePath: { eq: "reis3_dag5.JPG"}) {
      ...imageFragment
    }
    image39: file(relativePath: { eq: "reis3_dag6.jpeg"}) {
      ...imageFragment
    }
    image40: file(relativePath: { eq: "reis3_dag7.jpg"}) {
      ...imageFragment
    }
    image41: file(relativePath: { eq: "reis3_dag8.JPG"}) {
      ...imageFragment
    }
    image42: file(relativePath: { eq: "reis3_dag1.jpeg"}) {
      ...imageFragment
    }
    image43: file(relativePath: { eq: "reis4_dag1.jpg"}) {
      ...imageFragment
    }
    image44: file(relativePath: { eq: "stock1.jpeg"}) {
      ...imageFragment
    }
    image45: file(relativePath: { eq: "reis4_dag7.JPG"}) {
      ...imageFragment
    }
    image46: file(relativePath: { eq: "reis4_dag8.JPG"}) {
      ...imageFragment
    }
    image47: file(relativePath: { eq: "reis4_dag10.jpg"}) {
      ...imageFragment
    }
    image48: file(relativePath: { eq: "reis5_dag2.JPG"}) {
      ...imageFragment
    }
    image49: file(relativePath: { eq: "reis5_dag3.jpg"}) {
      ...imageFragment
    }
    image50: file(relativePath: { eq: "reis5_dag4.JPG"}) {
      ...imageFragment
    }
    image51: file(relativePath: { eq: "reis5_dag5.jpg"}) {
      ...imageFragment
    }
    image52: file(relativePath: { eq: "reis5_dag6.jpeg"}) {
      ...imageFragment
    }
    image53: file(relativePath: { eq: "reis6_dag1.jpeg"}) {
      ...imageFragment
    }
    image54: file(relativePath: { eq: "reis6_dag3.JPG"}) {
      ...imageFragment
    }
    image55: file(relativePath: { eq: "reis6_dag4.jpeg"}) {
      ...imageFragment
    }
    image56: file(relativePath: { eq: "reis6_dag5.JPG"}) {
      ...imageFragment
    }
    image57: file(relativePath: { eq: "reis6_dag6.JPG"}) {
      ...imageFragment
    }
    image58: file(relativePath: { eq: "reis6_dag7.jpg"}) {
      ...imageFragment
    }
    image59: file(relativePath: { eq: "reis6_dag10.JPG"}) {
      ...imageFragment
    }
    image60: file(relativePath: { eq: "stock3.jpg"}) {
      ...imageFragment
    }
    image61: file(relativePath: { eq: "FOTO2.jpg"}) {
      ...imageFragment
    }
    image62: file(relativePath: { eq: "FOTO3.jpg"}) {
      ...imageFragment
    }
    image63: file(relativePath: { eq: "FOTO4.jpg"}) {
      ...imageFragment
    }
          image64: file(relativePath: { eq: "FOTO18.jpg"}) {
      ...imageFragment
    }
          image65: file(relativePath: { eq: "FOTO12.jpg"}) {
      ...imageFragment
    }
          image66: file(relativePath: { eq: "FOTO13.jpg"}) {
      ...imageFragment
    }
          image67: file(relativePath: { eq: "FOTO14.jpg"}) {
      ...imageFragment
    }
          image68: file(relativePath: { eq: "FOTO15.jpg"}) {
      ...imageFragment
    }
          image69: file(relativePath: { eq: "FOTO16.jpg"}) {
      ...imageFragment
    }
          image70: file(relativePath: { eq: "FOTO17.jpg"}) {
      ...imageFragment
    }
    Test8: file(relativePath: {eq: "logo_footer1_bewerkt.png"}) {
      childImageSharp {
        gatsbyImageData(width: 750)
      }
      relativePath
    }
      Test9: file(relativePath: {eq: "logo_footer2_bewerkt.png"}) {
      childImageSharp {
        gatsbyImageData(width: 750)
      }
      relativePath
    }
      Test10: file(relativePath: {eq: "logo_footer3_bewerkt.png"}) {
      childImageSharp {
        gatsbyImageData(width: 750)
      }
      relativePath
    }
      Test11: file(relativePath: {eq: "logo_footer4_bewerkt.png"}) {
      childImageSharp {
        gatsbyImageData(width: 750)
      }
      relativePath
    }
  }
`

