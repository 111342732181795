import { IGatsbyImageData } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import { getImageByRelativePath } from '../../shared/utilities';

export type TripMetaData = {
    title: string;
    description: string;
    durationInDays: number;
    price: string
    keywords: string[];
    image: IGatsbyImageData;
};

export type TripDay = {
    title: string;
    subTitle: string;
    description: string;
    keywords: string[];
    image?: IGatsbyImageData;
};

export type TripType = {
    metaData: TripMetaData;
    days: TripDay[];
};

export const getTrips = (data: any): TripType[] => {
    const { t } = useTranslation('translation', { keyPrefix: 'islandHoppingPage' });
    return [
        {
            metaData: {
                title: t('trip1name'),
                description: t('trip1text1'),
                keywords: [
                    t('trip1feature1'),
                    t('trip1feature2'),
                    t('trip1feature3'),
                    t('trip1feature4')
                ],
                durationInDays: 5,
                price: '€1499',
                image: getImageByRelativePath('wonderwater_galapagos.JPG', data)
            },
            days: [
                {
                    title: t('trip1dag1header'), 
                    subTitle: t('trip1dag1subheader'),
                    description: t('trip1dag1content'),
                    keywords: ['Lunch'],
                    image: getImageByRelativePath('tortoise.jpg', data) 
                },
                {
                    title: t('trip1dag2header'),
                    subTitle: t('trip1dag2subheader'),
                    description: t('trip1dag2content'),                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('reis1_dag2.JPG', data)
                },
                {
                    title: t('trip1dag3header'),
                    subTitle: t('trip1dag3subheader'),
                    description: t('trip1dag3content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('kajak.jpg', data)
                },
                {
                    title: t('trip1dag4header'),
                    subTitle: t('trip1dag4subheader'),
                    description: t('trip1dag4content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('bartolome.jpg', data)
                },
                {
                    title: t('trip1dag5header'),
                    subTitle: t('trip1dag5subheader'),
                    description: t('trip1dag5content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('foto_darwin_center.jpg', data)
                },

            ],
        },

        {
            metaData: {
                title: t('trip2name'),
                description: t('trip2text1'),
                keywords: [
                    t('trip2feature1'),
                    t('trip2feature2'),
                    t('trip2feature3'),
                    t('trip2feature4')
                ],
                durationInDays: 7,
                price: '€1799',
                image: getImageByRelativePath('tijd_voor_actie.JPG', data)
            },
            days: [
                {
                    title: t('trip2dag1header'),
                    subTitle: t('trip2dag1subheader'),
                    description: t('trip2dag1content'),
                    keywords: ['Lunch'],
                    image: getImageByRelativePath('stock6.jpeg', data)
                },
                {
                    title: t('trip2dag2header'),
                    subTitle: t('trip2dag2subheader'),
                    description: t('trip2dag2content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('flamingo_isabela.jpg', data) 
                },
                {
                    title: t('trip2dag3header'),
                    subTitle: t('trip2dag3subheader'),
                    description: t('trip2dag3content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('reis2_dag3.JPG', data) 
                },
                {
                    title: t('trip2dag4header'),
                    subTitle: t('trip2dag4subheader'),
                    description: t('trip2dag4content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('reis2_dag4.jpg', data) 
                },
                {
                    title: t('trip2dag5header'), 
                    subTitle: t('trip2dag5subheader'),
                    description: t('trip2dag5content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('FOTO14.jpg', data)
                },
                {
                    title: t('trip2dag6header'),
                    subTitle: t('trip2dag6subheader'),
                    description: t('trip2dag6content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('FOTO15.jpg', data) 
                },
                {
                    title: t('trip2dag7header'), 
                    subTitle: t('trip2dag7subheader'),
                    description: t('trip2dag7content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('foto_darwin_center.jpg', data) 
                },
            ],
        },

        {
            metaData: {
                title: t('trip3name'),
                description: t('trip3text1'),
                keywords: [
                    t('trip3feature1'),
                    t('trip3feature2'),
                    t('trip3feature3'),
                    t('trip3feature4')
                ],
                durationInDays: 8,
                price: '€2299',
                image: getImageByRelativePath('FOTO2.jpg', data)
            },
            days: [
                {
                    title: t('trip3dag1header'), 
                    subTitle: t('trip3dag1subheader'),
                    description: t('trip3dag1content'),
                    keywords: ['Lunch'],
                    image: getImageByRelativePath('reis3_dag1.jpeg', data) 
                },
                {
                    title: t('trip3dag2header'), 
                    subTitle: t('trip3dag2subheader'),
                    description: t('trip3dag2content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('reis3_dag2.JPG', data) 
                },
                {
                    title: t('trip3dag3header'), 
                    subTitle: t('trip3dag3subheader'),
                    description: t('trip3dag3content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('FOTO16.jpg', data) 
                },
                {
                    title: t('trip3dag4header'),
                    subTitle: t('trip3dag4subheader'),
                    description: t('trip3dag4content'),
                    keywords: [t('breakfast'), 'Snack'],
                    image: getImageByRelativePath('stock2.jpg', data) 
                },
                {
                    title: t('trip3dag5header'),
                    subTitle: t('trip3dag5subheader'),
                    description: t('trip3dag5content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('reis3_dag5.JPG', data)
                },
                {
                    title: t('trip3dag6header'), 
                    subTitle: t('trip3dag6subheader'),
                    description: t('trip3dag6content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('reis3_dag6.jpeg', data)
                },
                {
                    title: t('trip3dag7header'),
                    subTitle: t('trip3dag7subheader'),
                    description: t('trip3dag7content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('reis3_dag7.jpg', data)
                },
                {
                    title: t('trip3dag8header'),
                    subTitle: t('trip3dag8subheader'),
                    description: t('trip3dag8content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('reis3_dag8.JPG', data)
                },
            ],
        },

        {
            metaData: {
                title: t('trip4name'),
                description: t('trip4text1'),
                keywords: [
                    t('trip4feature1'),
                    t('trip4feature2'),
                    t('trip4feature3'),
                    t('trip4feature4')
                ],
                durationInDays: 10,
                price: '€2799',
                image: getImageByRelativePath('reis4_banner.jpg', data)
            },
            days: [
                {
                    title: t('trip4dag1header'),
                    subTitle: t('trip4dag1subheader'),
                    description: t('trip4dag1content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('reis4_dag1.jpg', data) 
                },
                {
                    title: t('trip4dag2header'),
                    subTitle: t('trip4dag2subheader'),
                    description: t('trip4dag2content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('mantaray.jpeg', data)
                },
                {
                    title: t('trip4dag3header'), 
                    subTitle: t('trip4dag3subheader'),
                    description: t('trip4dag3content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('FOTO17.jpg', data) 
                },
                {
                    title: t('trip4dag4header'), 
                    subTitle: t('trip4dag4subheader'),
                    description: t('trip4dag4content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('eagle_ray.jpg', data) 
                },
                {
                    title: t('trip4dag5header'), 
                    subTitle: t('trip4dag5subheader'),
                    description: t('trip4dag5content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('stock6.jpeg', data) 
                },
                {
                    title: t('trip4dag6header'), 
                    subTitle: t('trip4dag6subheader'),
                    description: t('trip4dag6content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('FOTO18.jpg', data) 
                },
                {
                    title: t('trip4dag7header'), 
                    subTitle: t('trip4dag7subheader'),
                    description: t('trip4dag7content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('reis4_dag7.JPG', data) 
                },
                {
                    title: t('trip4dag8header'), 
                    subTitle: t('trip4dag8subheader'),
                    description: t('trip4dag8content'),
                    keywords: [t('breakfast'), 'Snack'],
                    image: getImageByRelativePath('reis4_dag8.JPG', data) 
                },

                {
                    title: t('trip4dag9header'), 
                    subTitle: t('trip4dag9subheader'),
                    description: t('trip4dag9content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('reis5_dag5.jpg', data) 
                },

                {
                    title: t('trip4dag10header'), 
                    subTitle: t('trip4dag10subheader'),
                    description: t('trip4dag10content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('reis4_dag10.jpg', data) 
                },
            ],
        },

        {
            metaData: {
                title: t('trip5name'),
                description: t('trip5text1'),
                keywords: [
                    t('trip5feature1'),
                    t('trip5feature2'),
                    t('trip5feature3'),
                    t('trip5feature4')
                ],
                durationInDays: 12,
                price: '€2999',
                image: getImageByRelativePath('FOTO3.jpg', data)
            },
            days: [
                {
                    title: t('trip5dag1header'), 
                    subTitle: t('trip5dag1subheader'),
                    description: t('trip5dag1content'),
                    keywords: ['Lunch'],
                    image: getImageByRelativePath('tortoise.jpg', data) 
                },
                {
                    title: t('trip5dag2header'), 
                    subTitle: t('trip5dag2subheader'),
                    description: t('trip5dag2content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('reis5_dag2.JPG', data) 
                },
                {
                    title: t('trip5dag3header'), 
                    subTitle: t('trip5dag3subheader'),
                    description: t('trip5dag3content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('reis5_dag3.jpg', data) 
                },
                {
                    title: t('trip5dag4header'), 
                    subTitle: t('trip5dag4subheader'),
                    description: t('trip5dag4content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('reis5_dag4.JPG', data) 
                },
                {
                    title: t('trip5dag5header'), 
                    subTitle: t('trip5dag5subheader'),
                    description: t('trip5dag5content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('reis5_dag5.jpg', data) 
                },
                {
                    title: t('trip5dag6header'), 
                    subTitle: t('trip5dag6subheader'),
                    description: t('trip5dag6content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('bartolome.jpg', data) 
                },

                {
                    title: t('trip5dag7header'), 
                    subTitle: t('trip5dag7subheader'),
                    description: t('trip5dag7content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('stock1.jpeg', data) 
                },
                {
                    title: t('trip5dag8header'), 
                    subTitle: t('trip5dag8subheader'),
                    description: t('trip5dag8content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('reis3_dag6.jpeg', data) 
                },
                {
                    title: t('trip5dag9header'), 
                    subTitle: t('trip5dag9subheader'),
                    description: t('trip5dag9content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('flamingo_isabela.jpg', data) 
                },
                {
                    title: t('trip5dag10header'), 
                    subTitle: t('trip5dag10subheader'),
                    description: t('trip5dag10content'),
                    keywords: [t('breakfast'), "Snack"],
                    image: getImageByRelativePath('stock2.jpg', data) 
                },
                {
                    title: t('trip5dag11header'), 
                    subTitle: t('trip5dag11subheader'),
                    description: t('trip5dag11content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('foto_darwin_center.jpg', data) 
                },

                {
                    title: t('trip5dag12header'), 
                    subTitle: t('trip5dag12subheader'),
                    description: t('trip5dag12content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('tijd_voor_actie.JPG', data) 
                }
            ],
        },

        {
            metaData: {
                title: t('trip6name'),
                description: t('trip6text1'),
                keywords: [
                    t('trip6feature1'),
                    t('trip6feature2'),
                    t('trip6feature3'),
                    t('trip6feature4')
                ],
                durationInDays: 10,
                price: '€2550',
                image: getImageByRelativePath('FOTO4.jpg', data)
            },
            days: [
                {
                    title: t('trip6dag1header'), 
                    subTitle: t('trip6dag1subheader'),
                    description: t('trip6dag1content'),
                    keywords: ['Lunch'],
                    image: getImageByRelativePath('reis6_dag1.jpeg', data) 
                },
                {
                    title: t('trip6dag2header'), 
                    subTitle: t('trip6dag2subheader'),
                    description: t('trip6dag2content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('stock6.jpeg', data) 
                },
                {
                    title: t('trip6dag3header'), 
                    subTitle: t('trip6dag3subheader'),
                    description: t('trip6dag3content'),
                    keywords: [t('breakfast'), "Lunch"],
                    image: getImageByRelativePath('reis6_dag3.JPG', data)
                },
                {
                    title: t('trip6dag4header'),
                    subTitle: t('trip6dag4subheader'),
                    description: t('trip6dag4content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('reis6_dag4.jpeg', data)
                },
                {
                    title: t('trip6dag5header'),
                    subTitle: t('trip6dag5subheader'),
                    description: t('trip6dag5content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('reis6_dag5.JPG', data)
                },
                {
                    title: t('trip6dag6header'),
                    subTitle: t('trip6dag6subheader'),
                    description: t('trip6dag6content'),
                    keywords: [t('breakfast'), "Lunch"],
                    image: getImageByRelativePath('reis6_dag6.JPG', data)
                },
                {
                    title: t('trip6dag7header'),
                    subTitle: t('trip6dag7subheader'),
                    description: t('trip6dag7content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('reis6_dag7.jpg', data)
                },
                {
                    title: t('trip6dag8header'),
                    subTitle: t('trip6dag8subheader'),
                    description: t('trip6dag8content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('stock3.jpg', data)
                },

                {
                    title: t('trip6dag9header'),
                    subTitle: t('trip6dag9subheader'),
                    description: t('trip6dag9content'),
                    keywords: [t('breakfast'), 'Lunch'],
                    image: getImageByRelativePath('kicker_rock.jpg', data)
                },

                {
                    title: t('trip6dag10header'),
                    subTitle: t('trip6dag10subheader'),
                    description: t('trip6dag10content'),
                    keywords: [t('breakfast')],
                    image: getImageByRelativePath('reis6_dag10.JPG', data)
                },
            ],
        },
    ];
}